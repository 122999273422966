import axiosBase from "../axios/axios";

const profileService = {
  getProfileSelections: async (profileId) => {
    try {
      const { data } = await axiosBase.get(
        `/profile/getProfileSelections/${profileId}`
      );
      return data;
    } catch (err) {
      console.log(err, "error in getProfileSelections");
    }
  },
  changeAvatar: async (profile) => {
    try {
      const { data } = await axiosBase.post(`/profile/changeAvatar`, {
        profile,
      });
      return data;
    } catch (err) {
      console.log(err, "error in changeAvatar");
    }
  },
  updateProfileSelections: async (profile) => {
    try {
      const { data } = await axiosBase.post(
        `/profile/updateProfileSelections`,
        {
          profile,
        }
      );
      return data;
    } catch (err) {
      console.log(err, "error in updateProfileSelections");
    }
  },
  getHouseholdNames: async (householdId) => {
    try {
      const { data } = await axiosBase.get(
        `/profile/getHouseholdNames/${householdId}`
      );
      return data;
    } catch (err) {
      console.log(err, "error in getHouseholdNames");
    }
  },
  getBoxColor: async (profileId) => {
    try {
      const { data } = await axiosBase.get(`/profile/getBoxColor/${profileId}`);
      return data;
    } catch (err) {
      console.log(err, "error in getBoxColors");
    }
  },
  deleteProfile: async (profileId) => {
    try {
      const { data } = await axiosBase.post(`/profile/deleteProfile/`, {
        profileId,
      });
      return data;
    } catch (err) {
      console.log(err, "error in deleteProfile");
    }
  },
  getUserList: async ({ limit, offset }) => {
    try {
      const { data } = await axiosBase.get(
        `/profile/getUserList/${limit}/${offset}`
      );
      return data;
    } catch (err) {
      console.log(err, "error in getUserList");
    }
  },
  changeChildName: async (name, profileId) => {
    try {
      const { data } = await axiosBase.post(`/profile/changeChildName`, {
        name,
        profileId,
      });
      return data;
    } catch (err) {
      console.log(err, "error in changeChildName");
    }
  },
  searchProfile: async (searchTerm) => {
    try {
      const { data } = await axiosBase.get(
        `/profile/searchProfile/${searchTerm}`
      );
      return data;
    } catch (err) {
      console.log(err, "error in searchUserByEmail");
    }
  },
};
export default profileService;
