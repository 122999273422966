import "./styles/homepage.css";
import { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import HeaderMobile from "./header-mobile";
import lollipop from "../images/lollipop.png";
import vegtables from "../images/vegtables.png";
import Notification from "./notification";
import { IconContext } from "react-icons";
import {
  MdOutlineArrowForwardIos,
  MdOutlineArrowBackIosNew,
} from "react-icons/md";
import WeeklySupply from "./weekly-supply";
import WeeklyRecipes from "./weekly-recipes";
import Instagram from "./instagram";
import CarouselComponent from "./carousel";
import Testimonials from "./testimonials";
import MobileFooter from "./footer-mobile";
import HeaderDesktop from "./header-desktop";
import FooterDesktop from "./footer-desktop";
import BoxProfile from "./box-profile";
import TodaysSuggestion from "./todays-suggestion";
import { setNotificationsRedux } from "../redux/reducer";
import { useDispatch, useSelector } from "react-redux";
import householdService from "../services/householdService";
import profileService from "../services/profileService";
import recipeService from "../services/recipeService";
import {
  setChildProfilesRedux,
  setHouseholdIdRedux,
  setCurrentChildProfileRedux,
  setWeeklyRecipesRedux,
  setWeeklySuppliesRedux,
  setRole,
  setGeneralRecipes,
  setPersonalRecipes,
  setTestimonials,
  setArticles,
  setBoxColor,
  setTimeZone,
} from "../redux/reducer";
import localAvatarImages from "../utils/avatars-images-array";
import { boxImages } from "../utils/box-images";
import { useNavigate } from "react-router-dom";
import articleService from "../services/articleService";
import testimonialService from "../services/testimonialService";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const HomePage = () => {
  const mobile = useMediaQuery({ maxWidth: 800 });
  const [notifications, setNotifications] = useState([]);
  const [tokenExist, setTokenExist] = useState(false);
  const [householdEmail, setHouseholdEmail] = useState(null);
  const [lock, setLock] = useState(false);
  // const currentProfileRedux = useSelector(
  //   (state) => state.mainSlice.currentChildProfile
  // );
  const navigate = useNavigate();
  const reduxNotifications = useSelector(
    (state) => state.mainSlice.notifications
  );
  const boxImageRedux = useSelector((state) => state.mainSlice.boxColor);
  const dontFetchUserDetails = useSelector(
    (state) => state.mainSlice.dontFetchUserDetails
  );
  const boxCleanPopupOpenRedux = useSelector(
    (state) => state.mainSlice.boxCleanPopupOpen
  );
  const recipesRedux = useSelector((state) => state.mainSlice.generalRecipes);
  const { t, i18n } = useTranslation();
  const languageDirection = i18n.language === "he" ? "rtl" : "ltr";
  const { token, profileId, boxColor } = useParams();

  useEffect(() => {
    if (token && profileId && boxColor) {
      localStorage.setItem("token", token);
      localStorage.setItem("profileId", profileId);
      localStorage.setItem("boxColor", boxColor);
      dispatch(setBoxColor(boxImages[boxColor]));
      setTokenExist(true);

      // if (boxColor) {
      //   dispatch(setBoxColor(boxImages[boxColor]));
      // } else {
      // no box color redirect to getyoopi kyc for login
      // window.location.href = "https://reg.getyoopi.com/kyc/login";
      // }

      // const fetchBoxColor = async () => {
      //   const color = await profileService.getBoxColor(profileId);
      //   if (color.message === "success") {
      //     const boxColorNumber = color.box_identifier.slice(0, 3);
      //     dispatch(setBoxColor(boxImages[boxColorNumber]));
      //   } else if (color.message === "No box found") {
      //     // if no box found redirect to getyoopi kyc for login
      //     console.log("box problem");
      //     window.location.href = "https://reg.getyoopi.com/kyc/login";
      //   }
      // };

      // fetchBoxColor();
    } else {
      setTokenExist(false);
    }
  }, []);

  const dispatch = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await householdService.getHouseholdInformation();
        if (response.message === "success") {
          setHouseholdEmail(response.email);
          dispatch(setRole(response.householdRole));
          dispatch(setTimeZone(response.timeZone));
          localStorage.setItem("lang", response.language);

          if (response.language !== i18n.language) {
            i18n.changeLanguage(response.language);
          }

          // if avatar is a local image we need to assign the image to the profile and a avatarName for later use
          let updatedProfiles = [];
          if (response.childProfiles.length) {
            response.childProfiles?.forEach((profile) => {
              if (profile.avatar.length < 11) {
                localAvatarImages.forEach((image) => {
                  if (
                    image.name.toLowerCase() === profile.avatar.toLowerCase()
                  ) {
                    updatedProfiles.push({
                      ...profile,
                      avatar: image.img,
                      avatarName: image.name,
                    });
                  }
                });
              } else {
                updatedProfiles.push({ ...profile, avatarName: "Captured" });
              }
            });
          }

          let matchingProfile;
          if (profileId) {
            // if profileId exist in url we need to find the matching profile
            matchingProfile = updatedProfiles.filter(
              (child) => child.id === parseInt(profileId)
            );
          } else {
            // if profileId doesn't exist in url we need to find the latest profile

            const localProfileId = localStorage.getItem("profileId");
            if (localProfileId) {
              matchingProfile = updatedProfiles.filter(
                (child) => child.id === parseInt(localProfileId)
              );
            } else {
              matchingProfile = updatedProfiles.reduce((acc, profile) => {
                if (
                  !acc ||
                  new Date(profile.created_at) > new Date(acc.created_at)
                ) {
                  return profile;
                }
                return acc;
              }, null);
              matchingProfile = matchingProfile ? [matchingProfile] : [];
            }
          }

          dispatch(setChildProfilesRedux(updatedProfiles));
          dispatch(setHouseholdIdRedux(response.householdId));
          let currentProfile;
          const profileSelections = await profileService.getProfileSelections(
            matchingProfile[0].id
          );
          if (profileSelections.message === "success") {
            currentProfile = profileSelections.profile;
          } else {
            console.log(profileSelections, "error fetching profile selections");
          }

          dispatch(setCurrentChildProfileRedux(currentProfile));

          const getRecipes = await recipeService.getRecipes(currentProfile.id);

          if (getRecipes.message === "success") {
            const personalRecipes = getRecipes.personalRecipes || []; // Ensure it's an array or an empty array
            const generalRecipes = getRecipes.generalRecipes || []; // Ensure it's an array or an empty array
            personalRecipes.sort(
              (a, b) => new Date(b.created_at) - new Date(a.created_at)
            );

            generalRecipes.sort(
              (a, b) => new Date(b.created_at) - new Date(a.created_at)
            );

            dispatch(setGeneralRecipes(generalRecipes));
            dispatch(setPersonalRecipes(personalRecipes));
          }
          // } else {
          // if current profile is already set in redux we don't need to set it again
          // user is navigating from another page
          // currentProfile = currentProfileRedux;
          // }

          if (!boxImageRedux) {
            const boxColor = localStorage.getItem("boxColor");
            if (boxColor) {
              dispatch(setBoxColor(boxImages[boxColor]));
            } else {
              // if no box found redirect to getyoopi kyc for login
              window.location.href = "https://reg.getyoopi.com/kyc/login";
            }
          }

          // fetch articles and testimonials and notifications
          const fetchArticles = await articleService.getArticles(
            currentProfile.id
          );
          const fetchNotifications = await articleService.getNotifications(
            currentProfile.id
          );
          const fetchTestimonials = await testimonialService.getTestimonials(
            currentProfile.id
          );

          if (fetchArticles.message === "success") {
            dispatch(setArticles(fetchArticles.articles));
          }
          if (fetchTestimonials.message === "success") {
            dispatch(setTestimonials(fetchTestimonials.testimonials));
          }
          if (fetchNotifications.message === "success") {
            dispatch(setNotificationsRedux(fetchNotifications.notifications));
          }

          const fetchWeeklyRecipes = await recipeService.getWeeklyRecipes(
            currentProfile.id
          );
          if (fetchWeeklyRecipes.message === "success") {
            dispatch(setWeeklyRecipesRedux(fetchWeeklyRecipes.weeklyRecipes));

            if (fetchWeeklyRecipes.weeklySupplies?.length) {
              dispatch(
                setWeeklySuppliesRedux(fetchWeeklyRecipes.weeklySupplies)
              );
            } else {
              dispatch(setWeeklySuppliesRedux([]));
            }
          }
        } else {
          console.log(response, "error fetching household information 1");
        }
      } catch (err) {
        console.log(err, "error fetching household information");
      }
    };

    if (tokenExist) {
      // if token exist in url we need to fetch data
      fetchData();
    } else {
      // if token doesn't exist in url we need to check if we have a token and profileId in local storage
      const profileId = localStorage.getItem("profileId");
      if (profileId) {
        if (dontFetchUserDetails) {
          // if dontFetchUserDetails is true we don't need to fetch user details
          // that means the user changed profile in mobile view
          return;
        }

        fetchData();
      } else {
        // alert("un authorized access");
        // window.location.href = "https://reg.getyoopi.com/kyc/login";
        // if no token found we need to redirect to getyoopi kyc for login
        return;
      }
    }
  }, []);

  useEffect(() => {
    setNotifications(reduxNotifications);
  }, [reduxNotifications]);
  useEffect(() => {
    // make sure only happen once
    if (lock) return;

    if (householdEmail !== null) {
      if (
        window &&
        window.sp &&
        window.sp.popups &&
        window.sp.popups.variables
      ) {
        window.sp.popups.variables({
          lang: i18n.language,
        });
      }

      // progressier lang + email

      window.progressier.add({
        email: householdEmail,
      });
      window.progressier.add({
        tags: i18n.language,
      });

      setLock(true);
    }
  }, [householdEmail]);
  return (
    <>
      <div
        className={
          boxCleanPopupOpenRedux
            ? "home-page-container no-events"
            : "home-page-container"
        }
      >
        {/* mobile view */}
        {mobile ? (
          <>
            <HeaderMobile location="home" />
            <div className="notifications-wrapper">
              {notifications.length > 0 && (
                <>
                  {notifications.map((notification) => {
                    return (
                      <Notification
                        key={notification.id}
                        notification={notification}
                      />
                    );
                  })}
                </>
              )}
            </div>
            <BoxProfile />
            {recipesRedux?.length > 0 && <TodaysSuggestion />}
            {/* <TodaysSuggestion /> */}
            <WeeklySupply />
            <WeeklyRecipes context="home" navigate={navigate} />
            <div className="home-page-images">
              <img src={vegtables} alt="" className="vegetables" />

              <img src={lollipop} alt="" className="lollipop" />
            </div>
            <Instagram />
            <CarouselComponent />
            <div
              className="visit-blog"
              style={{ direction: languageDirection }}
              onClick={() =>
                window.open("https://getyoopi.com/blog-post/", "_blank")
              }
            >
              <span>{t("visit_blog")}</span>
              <div className="arrow">
                <IconContext.Provider value={{ className: "arrow-icon" }}>
                  {languageDirection === "rtl" ? (
                    <MdOutlineArrowBackIosNew style={{ color: "#f68222" }} />
                  ) : (
                    <MdOutlineArrowForwardIos style={{ color: "#f68222" }} />
                  )}
                </IconContext.Provider>
              </div>
            </div>
            <Testimonials />
            <MobileFooter />
          </>
        ) : (
          <>
            {/* desktop view */}
            <HeaderDesktop />
            <div className="home-page-desktop-wrapper">
              <div className="home-page-desktop-profile">
                <BoxProfile />
                {recipesRedux?.length > 0 ? (
                  <TodaysSuggestion />
                ) : (
                  <>
                    <div className="no-suggestions-desktop">
                      <p style={{ direction: languageDirection, color: "red" }}>
                        {t("no_suggestions")}
                      </p>
                    </div>
                  </>
                )}

                <Testimonials />
              </div>
              <div className="desktop-separator"></div>
              <div className="home-page-desktop-activities">
                <div className="top-row">
                  <WeeklyRecipes context="home" navigate={navigate} />
                </div>
                <div className="middle-row">
                  <WeeklySupply />
                </div>
                <div className="bottom-row">
                  <CarouselComponent />
                  <Instagram />
                </div>
              </div>
            </div>
            <FooterDesktop />
          </>
        )}
      </div>
    </>
  );
};

export default HomePage;
