import HeaderMobile from "./header-mobile";
import "./styles/edit-profile.css";
import { BsPencil } from "react-icons/bs";
import { IconContext } from "react-icons";
import { useState, useEffect, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { useSelector, useDispatch } from "react-redux";
import localAvatarImages from "../utils/avatars-images-array";
import Select from "react-select";
import generalData from "../utils/static-data";
import { HiOutlineChevronUp, HiOutlineChevronDown } from "react-icons/hi";
import { AiOutlineCloseCircle } from "react-icons/ai";
import HeaderDesktop from "./header-desktop";
import { useNavigate } from "react-router-dom";
import FooterDesktop from "./footer-desktop";
import { toast } from "react-toastify";
import profileService from "../services/profileService";
import {
  setCurrentChildProfileRedux,
  setItemForDeletion,
  setDeletionPopupOpenRedux,
  setDeletionPopupContext,
  setDontFetchUserDetails,
} from "../redux/reducer";
import { useTranslation } from "react-i18next";
import householdService from "../services/householdService";
const EditProfile = () => {
  const { t, i18n } = useTranslation();
  const languageDirection = i18n.language === "he" ? "rtl" : "ltr";
  const [childProfile, setChildProfile] = useState({});
  const [favoriteFood, setFavoriteFood] = useState([]);
  const [favoriteFoodSelectOpen, setFavoriteFoodSelectOpen] = useState(false);

  const [allergies, setAllergies] = useState([]);
  const [allergiesSelectOpen, setAllergiesSelectOpen] = useState(false);

  const [hubbies, setHubbies] = useState([]);
  const [hubbiesSelectOpen, setHubbiesSelectOpen] = useState(false);

  const [environment, setEnvironment] = useState([]);
  const [environmentSelectOpen, setEnvironmentSelectOpen] = useState(false);

  const [mealsSelection, setMealsSelection] = useState([
    { value: 1, label: `${t("Including meals")}` },
    {
      value: 0,
      label: `${t("Not including meals")}`,
    },
  ]);
  const [mealsIncluded, setMealsIncluded] = useState({});
  const mealsSelectRef = useRef(null);
  const [mealsSelectOpen, setMealsSelectOpen] = useState(false);
  const [mealsSelectOptionsIsOpen, setMealsSelectOptionsIsOpen] =
    useState(false);
  const [selectedEnvironment, setSelectedEnvironment] = useState([]);
  const [environmentAccordionHeight, setEnvironmentAccordionHeight] =
    useState("");
  const [name, setName] = useState("");
  const [nameInputOpen, setNameInputOpen] = useState(false);
  const [nameError, setNameError] = useState("");
  const [language, setLanguage] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const currentChildProfileRedux = useSelector(
    (state) => state.mainSlice.currentChildProfile
  );

  const mobile = useMediaQuery({ maxWidth: 800 });
  const mobile400Px = useMediaQuery({ maxWidth: 400 });
  const translatedData = {
    foods: generalData.foods.map((food) => ({
      ...food,
      label: t(`${food.label}`),
    })),
    allergies: generalData.allergies.map((allergy) => ({
      ...allergy,
      label: t(`${allergy.label}`),
    })),
    hubbies: generalData.hubbies.map((hubby) => ({
      ...hubby,
      label: t(`${hubby.label}`),
    })),
    environment: generalData.environment.map((env) => ({
      ...env,
      label: t(`${env.label}`),
    })),
  };

  const languageOptions = [
    { value: "en", label: `${t("English")}` },
    { value: "he", label: `${t("Hebrew")}` },
  ];
  const fetchProfileSelections = async (profileId) => {
    const response = await profileService.getProfileSelections(profileId);
    return response;
  };
  useEffect(() => {
    const fetchData = async () => {
      if (currentChildProfileRedux?.id) {
        const response = await fetchProfileSelections(
          currentChildProfileRedux?.id
        );
        if (response.message === "success") {
          setName(response.profile.first_name);
          dispatch(setCurrentChildProfileRedux(response.profile));
        } else if (response.message === "No profile selections found") {
          toast.error(`${t(response.message)}`);
        } else {
          console.log(
            response,
            "response.message from fetchProfileSelections else "
          );
        }
        // fetch profile selections
      }
    };
    fetchData();
    const lang = localStorage.getItem("lang")
      ? localStorage.getItem("lang")
      : "he";
    if (lang !== i18n.language) {
      i18n.changeLanguage(lang);
    }

    if (lang === "en") {
      setLanguage(languageOptions[0]);
    } else {
      setLanguage(languageOptions[1]);
    }
  }, []);

  useEffect(() => {
    if (currentChildProfileRedux?.id) {
      let updatedProfile = {};
      if (currentChildProfileRedux.avatar.length < 11) {
        // length < 11 its a local image we assign it to the profile
        localAvatarImages.forEach((image) => {
          if (
            image.name.toLocaleLowerCase() ===
            currentChildProfileRedux.avatar.toLocaleLowerCase()
          ) {
            updatedProfile = {
              ...currentChildProfileRedux,
              avatar: image.img,
            };
          }
        });
      } else {
        // if length > 11 its a URL we keep it as it is
        updatedProfile = { ...currentChildProfileRedux };
      }
      setChildProfile(updatedProfile);
    } else {
      navigate("/");
    }
  }, [currentChildProfileRedux]);

  useEffect(() => {
    if (childProfile?.childFoods !== "" && childProfile?.childFoods !== null) {
      const childFoodsArray = (childProfile.childFoods || "").split(",");

      const profileFoods = generalData.foods.filter((item) =>
        childFoodsArray.includes(item.label)
      );

      // Extract foods that match the translated data based on value property of profileFoods
      const profileFoodsCorrectLanguage = profileFoods.map((item) => {
        const translatedFood = translatedData.foods.find(
          (food) => food.value === item.value
        );
        return translatedFood;
      });
      setFavoriteFood(profileFoodsCorrectLanguage);
    } else {
      setFavoriteFood([]);
    }

    if (
      childProfile?.childAllergies !== "" &&
      childProfile?.childAllergies !== null
    ) {
      const childAllergiesArray = (childProfile.childAllergies || "").split(
        ","
      );

      const profileAllergies = generalData.allergies.filter((item) =>
        childAllergiesArray.includes(item.label)
      );

      const profileAllergiesCorrectLanguage = profileAllergies.map((item) => {
        const translatedAllergy = translatedData.allergies.find(
          (allergy) => allergy.value === item.value
        );
        return translatedAllergy;
      });

      setAllergies(profileAllergiesCorrectLanguage);
    } else {
      // set option to none
      setAllergies([translatedData.allergies[0]]);
    }
    if (
      childProfile?.childHubbies !== "" ||
      childProfile?.childHubbies !== null ||
      childProfile?.childHubbies !== undefined
    ) {
      const childHubbiesArray = (childProfile.childHubbies || "").split(",");

      const profileHubbies = generalData.hubbies.filter((item) =>
        childHubbiesArray.includes(item.label)
      );

      const profileHubbiesCorrectLanguage = profileHubbies.map((item) => {
        const translatedHubby = translatedData.hubbies.find(
          (hubby) => hubby.value === item.value
        );
        return translatedHubby;
      });

      setHubbies(profileHubbiesCorrectLanguage);
    } else {
      setHubbies([]);
    }
    if (
      childProfile?.learning_environment !== "" ||
      childProfile?.learning_environment !== null
    ) {
      const translatedEnvironment = translatedData.environment.find(
        (item) => item.label === childProfile.learning_environment
      );

      if (translatedEnvironment) {
        // Find the matching environment in generalData using the translated label
        const profileEnvironment = generalData.environment.find(
          (item) => item.value === translatedEnvironment.value
        );
        if (profileEnvironment) {
          setSelectedEnvironment(translatedEnvironment);
        }
      }

      // Extract environment that match the translated data based on value property of profileEnvironment
      // const profileEnvironmentCorrectLanguage = profileEnvironment.map(
      //   (item) => {
      //     const translatedEnvironment = translatedData.environment.find(
      //       (env) => env.value === item.value
      //     );
      //     return translatedEnvironment;
      //   }
      // );
      // setSelectedEnvironment(profileEnvironmentCorrectLanguage);

      if (translatedEnvironment && translatedEnvironment[0]?.value !== 3) {
        if (childProfile.meals) {
          setMealsIncluded({
            value: 1,
            label: `${t("Including meals")}`,
          });
        } else {
          setMealsIncluded({
            value: 0,
            label: `${t("Not including meals")}`,
          });
        }

        setMealsSelectOpen(true);
      } else {
        setMealsSelectOpen(false);
      }
    } else {
      setSelectedEnvironment([]);
    }
    setEnvironment([]);
  }, [childProfile]);

  useEffect(() => {
    if (!mobile) {
      setFavoriteFoodSelectOpen(true);
      setAllergiesSelectOpen(true);
      setHubbiesSelectOpen(true);
      setEnvironmentSelectOpen(true);
    } else {
      setFavoriteFoodSelectOpen(false);
      setAllergiesSelectOpen(false);
      setHubbiesSelectOpen(false);
      setEnvironmentSelectOpen(false);
    }
  }, [mobile]);

  useEffect(() => {
    if (mobile) {
      // mobile
      if (environmentSelectOpen) {
        // accordion open
        setEnvironmentAccordionHeight("280px");
      } else {
        // accordion closed
        setEnvironmentAccordionHeight("75px");
      }
    } else {
      // desktop
      // accordion open always
      setEnvironmentAccordionHeight("280px");
    }
  }, [environmentSelectOpen]);

  const handleFoodRemove = (food) => {
    if (favoriteFood.length === 1) return;

    const updatedFoods = favoriteFood.filter(
      (item) => item.value !== food.value
    );
    setFavoriteFood(updatedFoods);
  };
  const handleFoodSelection = (selection) => {
    if (!selection || !selection.label) {
      // Handle the case where selection is undefined or doesn't have a label property.
      return;
    }
    if (favoriteFood.some((food) => food.label === selection.label)) return;

    const updatedFoods = [...favoriteFood, selection];
    setFavoriteFood(updatedFoods);
  };
  const handleAllergiesSelection = (selection) => {
    // If the selection is already in the allergies list, return

    if (allergies.some((allergy) => allergy.label === selection.label)) return;

    // If the user selects "None", remove all other allergies
    if (selection.value === 8) {
      setAllergies([selection]);
    } else {
      // Remove "None" if it exists in the allergies list
      const updatedAllergies = allergies.filter((option) => option.value !== 8);
      // Add the new selection
      updatedAllergies.push(selection);
      // Update the state with the updated allergies
      setAllergies(updatedAllergies);
    }
  };
  const handleRemoveAllergies = (allergy) => {
    const updatedAllergies = allergies.filter(
      (item) => item.label !== allergy.label
    );

    if (updatedAllergies.length === 0) {
      const updatedAllergy = translatedData.allergies.filter(
        (item) => item.value === 8
      );
      setAllergies(updatedAllergy);
      return;
    }
    setAllergies(updatedAllergies);
  };

  const handleHubbiesSelection = (selection) => {
    if (!selection || !selection.label) {
      // Handle the case where selection is undefined or doesn't have a label property.
      return;
    }

    if (hubbies.some((hubby) => hubby.value === selection.value)) return;

    const updated = [...hubbies, selection];
    setHubbies(updated);
  };
  const handleRemoveHubby = (hubby) => {
    if (hubbies.length === 1) return;

    // remove hubby from the list
    const updatedHubbies = hubbies.filter((item) => item.label !== hubby.label);

    setHubbies(updatedHubbies);
  };
  const handleEnvironmentSelection = (selection) => {
    if (!selection || !selection.label) {
      // Handle the case where selection is undefined or doesn't have a label property.
      return;
    }
    setSelectedEnvironment([selection]);

    if (selection.label !== "Other") {
      setMealsSelectOpen(true);
      if (mealsSelectRef?.current) {
        mealsSelectRef.current.style.display = "flex";
      }
    } else {
      mealsSelectRef.current.style.display = "none";
    }
  };

  const handleProfileChanges = async () => {
    let favoriteFoodChanged = false;
    if (favoriteFood !== null && favoriteFood.length) {
      const selectedFoodIds = favoriteFood.map((food) => food.value).sort();

      const foodsStringRedux = currentChildProfileRedux.childFoods;

      let foodIdsRedux;
      if (foodsStringRedux.includes(",")) {
        foodIdsRedux = foodsStringRedux
          .split(",")
          .map((item) => {
            let foundFood = generalData.foods.find(
              (food) => item === food.label
            );
            return foundFood ? foundFood.value : null;
          })
          .filter((value) => value !== null)
          .sort();
      } else {
        foodIdsRedux = foodsStringRedux
          ? [
              generalData.foods.find((food) => foodsStringRedux === food.label)
                .value,
            ]
          : [];
      }

      if (JSON.stringify(selectedFoodIds) !== JSON.stringify(foodIdsRedux)) {
        favoriteFoodChanged = true;
      }
    }

    let childAllergiesChanged = false;
    if (allergies.length && allergies !== null) {
      // extract allergies ids
      const selectedAllergiesIds = allergies
        .map((allergy) => allergy.value)
        .sort();

      // extract id based on general data
      let allergyIdsRedux;

      if (currentChildProfileRedux?.childAllergies !== null) {
        if (currentChildProfileRedux?.childAllergies.includes(",")) {
          // user had more then 1 allergy
          allergyIdsRedux = currentChildProfileRedux.childAllergies
            .split(",")
            .map((item) => {
              let foundAllergy = generalData.allergies.find(
                (allergy) => item === allergy.label
              );
              return foundAllergy ? foundAllergy.value : null;
            })
            .filter((value) => value !== null)
            .sort();
        } else {
          // user had only one allergy
          allergyIdsRedux = currentChildProfileRedux.childAllergies
            ? [
                generalData.allergies.find(
                  (allergy) =>
                    currentChildProfileRedux.childAllergies === allergy.label
                ).value,
              ]
            : [];
        }

        if (
          JSON.stringify(selectedAllergiesIds) !==
          JSON.stringify(allergyIdsRedux)
        ) {
          childAllergiesChanged = true;
        }
      } else {
        childAllergiesChanged = true;
      }
    }
    let hubbiesChanged = false;

    if (hubbies.length && favoriteFood !== null) {
      // extract hubbies ids
      const selectedHubbiesIds = hubbies.map((hubby) => hubby.value).sort();

      let hubbiesIdsRedux;
      if (
        currentChildProfileRedux?.childHubbies !== null &&
        currentChildProfileRedux?.childHubbies !== "null" &&
        currentChildProfileRedux?.childHubbies !== undefined
      ) {
        if (currentChildProfileRedux?.childHubbies.includes(",")) {
          // more then 1 hubby
          hubbiesIdsRedux = currentChildProfileRedux.childHubbies
            .split(",")
            .map((item) => {
              let foundHubby = generalData.hubbies.find(
                (hubby) => item === hubby.label
              );
              return foundHubby ? foundHubby.value : null;
            })
            .filter((value) => value !== null)
            .sort();
        } else {
          // only one hubby
          hubbiesIdsRedux = currentChildProfileRedux.childHubbies
            ? [
                generalData.hubbies.find(
                  (hubby) =>
                    currentChildProfileRedux.childHubbies === hubby.label
                ).value,
              ]
            : [];
        }
        if (
          JSON.stringify(selectedHubbiesIds) !== JSON.stringify(hubbiesIdsRedux)
        ) {
          hubbiesChanged = true;
        }
      } else {
        hubbiesChanged = true;
      }
    }
    let environmentChanged = false;

    if (selectedEnvironment.length) {
      if (
        currentChildProfileRedux.learning_environment !== null &&
        currentChildProfileRedux.learning_environment !== undefined &&
        currentChildProfileRedux.learning_environment !== ""
      ) {
        const selectedEnvironmentId = selectedEnvironment[0].value;

        // extract name based on translated data

        const currentEnvironmentObj = translatedData.environment.find(
          (item) => item.label === currentChildProfileRedux.learning_environment
        );

        if (
          currentEnvironmentObj !== undefined &&
          currentEnvironmentObj !== null
        ) {
          if (currentEnvironmentObj.value !== selectedEnvironmentId) {
            environmentChanged = true;
          }
        } else {
          environmentChanged = true;
        }
      } else {
        environmentChanged = true;
      }
    } else {
      environmentChanged = false;
    }

    let mealsChanged = false;
    let meals;

    // check meals selection
    if (mealsIncluded?.value) {
      // meals included
      meals = 1;
    } else {
      // meals not included
      meals = 0;
    }
    if (meals !== currentChildProfileRedux.meals) {
      mealsChanged = true;
    }

    if (
      !environmentChanged &&
      !favoriteFoodChanged &&
      !childAllergiesChanged &&
      !hubbiesChanged &&
      !mealsChanged
    ) {
      toast.error(t("No changes were made"));
      return;
    }

    const profileId = childProfile.id;
    const profile = {
      profileId,
      favoriteFoodChanged,
      favoriteFood,
      hubbiesChanged,
      hubbies,
      childAllergiesChanged,
      allergies,
      environmentChanged,
      selectedEnvironment,
      mealsChanged,
      meals,
    };
    try {
      const response = await profileService.updateProfileSelections(profile);
      if (response.message === "success") {
        toast.success(t("Profile updated"));
        dispatch(setDontFetchUserDetails(false));
        navigate("/");
      }
    } catch (err) {
      console.log(err, "error updating profile");
    }
  };

  const handleDeleteAccount = async () => {
    dispatch(setDeletionPopupContext("profile"));
    dispatch(setItemForDeletion(childProfile));
    dispatch(setDeletionPopupOpenRedux(true));
  };
  const handleLanguageChange = async (selection) => {
    if (selection.value === language.value) return;
    // Update the language state before changing the language globally
    try {
      const response = await householdService.updateLanguage(
        selection.value,
        childProfile.id
      );
      if (response.message === "success") {
        if (selection.value === "he") {
          toast.success("שפה נשמרה");
        } else {
          toast.success("Language saved");
        }
        i18n.changeLanguage(selection.value);
        localStorage.setItem("lang", selection.value);

        const newLanguage = {
          value: selection.value,
          label: `${t("Hebrew")}`,
        };
        setLanguage(newLanguage);

        navigate("/");
      } else {
        toast.error(t("Error updating language"));
        console.log(response, "response from updateLanguage");
      }
    } catch (err) {
      console.log(err, "error updating language");
    }
  };
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: "none",
      width: "100%",
      borderRadius: "4px",
      borderBottom: "2px solid #3C4964",
      marginBottom: "10px",
      height: "20px",
      "&:hover": {
        borderBottom: "2px solid #3C4964", // Remove the border on hover
      },
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none", // Hide the indicators separator (the vertical line)
    }),
    ValueContainer: (provided) => ({
      ...provided,
      border: "none",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#3C4964",
      direction: languageDirection, // Set the direction for the placeholder
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#3C4964",
    }),
    menu: (provided) => ({
      ...provided,
      direction: languageDirection, // Set the direction for the dropdown menu
    }),
    option: (provided) => ({
      ...provided,
      direction: languageDirection, // Set the direction for the options within the dropdown menu
    }),
  };
  const customLanguageStyles = {
    control: (provided) => ({
      ...provided,
      border: "none",
      width: "100%",
      borderRadius: "4px",
      borderBottom: "none",
      marginBottom: "10px",
      height: "20px",
      backgroundColor: mobile ? "transparent!important" : "#Ffffff",
      "&:hover": {
        borderBottom: "none", // Remove the border on hover
      },
      "&:focus": {
        border: "none", // Remove the border on focus
        outline: "none", // Remove the outline on focus
        boxShadow: "none", // Remove any box shadow on focus
      },
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none", // Hide the indicators separator (the vertical line)
    }),
    ValueContainer: (provided) => ({
      ...provided,
      border: "none",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#3C4964",
      direction: languageDirection, // Set the direction for the placeholder
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#3C4964",
    }),
    menu: (provided) => ({
      ...provided,
      direction: languageDirection,
    }),
    option: (provided) => ({
      ...provided,
      direction: languageDirection,
      fontSize: mobile400Px ? "0.8rem" : "1rem",
    }),
  };
  const handleNameChange = async () => {
    setNameError("");
    if (name === "") {
      setNameError(t("Name cannot be empty"));
      return;
    }
    if (name === childProfile.first_name) {
      setNameError(t("Name Not changed"));
      return;
    }
    if (name.length === 1) {
      setNameError(t("Name too short"));
      return;
    }

    try {
      const response = await profileService.changeChildName(
        name,
        childProfile.id
      );
      if (response.message === "success") {
        const newProfileName = await fetchProfileSelections(childProfile.id);
        if (newProfileName.message === "success") {
          setNameInputOpen(false);
          dispatch(setCurrentChildProfileRedux(newProfileName.profile));
          toast.success(t("Name updated"));
        }
      }
      console.log(response, "response from changeChildName");
    } catch (err) {
      console.log(err, "error updating name");
    }
  };
  return (
    <>
      {mobile ? <HeaderMobile location="edit-profile" /> : <HeaderDesktop />}
      {/* <HeaderMobile location="edit-profile" /> */}
      <div
        className={
          mobile ? "edit-profile-container" : "edit-profile-container desktop"
        }
      >
        <h2 style={{ direction: languageDirection }}>
          {t("edit_profile", { name: childProfile.first_name })}
        </h2>
        <div className="change-avatar">
          <div
            className="img-circle"
            style={{ backgroundImage: `url('${childProfile.avatar}')` }}
          >
            {/* <img src={childProfile.avatar} alt="" />{" "} */}
          </div>
          <div className="text" onClick={() => navigate("/change-avatar")}>
            <span>
              <IconContext.Provider value={{ className: "pencil-icon" }}>
                <BsPencil />
              </IconContext.Provider>
            </span>
            <span>{t("change_avatar")} </span>
          </div>
        </div>
        <div
          className={mobile ? "change-language" : "change-language desktop"}
          style={{
            direction: languageDirection,
            flexDirection: !mobile ? "column" : "row",
            padding: mobile ? "0 40px 0 40px" : "15px 15px 20px 15px",
          }}
        >
          <span>{t("Change Language")} </span>
          <Select
            options={languageOptions}
            styles={customLanguageStyles}
            onChange={handleLanguageChange}
            value={language}
            placeholder={t("select-language")}
          />
        </div>

        <div
          className={
            mobile ? "profile-selections" : "profile-selections desktop"
          }
        >
          {mobile && (
            <div
              className="select-reveal"
              style={{
                height: nameInputOpen ? "auto" : "60px",
              }}
            >
              <div
                className="top"
                style={{
                  justifyContent: "space-between",
                  flexDirection:
                    languageDirection === "rtl" ? "row-reverse" : "row",
                }}
                onClick={() => setNameInputOpen(!nameInputOpen)}
              >
                <span>{t("child_name")} </span>
                <span>
                  <IconContext.Provider value={{ className: "chevron-icon" }}>
                    {nameInputOpen ? (
                      <HiOutlineChevronDown />
                    ) : (
                      <HiOutlineChevronUp />
                    )}
                  </IconContext.Provider>
                </span>
              </div>
              {mobile && nameInputOpen && (
                <>
                  <div
                    className="select-wrapper"
                    style={{ direction: languageDirection }}
                  >
                    <input
                      type="text"
                      name=""
                      id=""
                      style={{
                        padding: "5px",
                      }}
                      className="changeName"
                      onChange={(e) => {
                        setNameError("");
                        setName(e.target.value);
                      }}
                      value={name}
                    />
                    {nameError && (
                      <span style={{ marginTop: "10px", color: "red" }}>
                        {nameError}
                      </span>
                    )}
                    <button onClick={handleNameChange}> {t("submit")}</button>
                  </div>
                </>
              )}
            </div>
          )}

          <div
            className={mobile ? "select-reveal" : "select-reveal desktop"}
            style={{
              height: favoriteFoodSelectOpen ? "auto" : "60px",
              minHeight: mobile ? "auto" : "280px",
            }}
          >
            <div
              className="top"
              style={{
                justifyContent: "space-between",
                flexDirection:
                  languageDirection === "rtl" ? "row-reverse" : "row",
              }}
              onClick={
                mobile
                  ? () => setFavoriteFoodSelectOpen(!favoriteFoodSelectOpen)
                  : null
              }
            >
              <span>{t("favorite_food")} </span>
              {mobile && (
                <span>
                  <IconContext.Provider value={{ className: "chevron-icon" }}>
                    {favoriteFoodSelectOpen ? (
                      <HiOutlineChevronDown />
                    ) : (
                      <HiOutlineChevronUp />
                    )}
                  </IconContext.Provider>
                </span>
              )}
            </div>

            {favoriteFoodSelectOpen && (
              <>
                <div
                  className={
                    mobile ? "select-wrapper" : "select-wrapper desktop"
                  }
                  style={{ direction: languageDirection }}
                >
                  <Select
                    options={translatedData?.foods}
                    styles={customStyles}
                    onChange={handleFoodSelection}
                    placeholder={t("select")}
                  />
                </div>
                <div
                  className="pre-selected"
                  style={{ width: mobile ? "100%" : "18vw" }}
                >
                  {favoriteFood?.length > 0 &&
                    favoriteFood?.map((food) => {
                      return (
                        <div
                          key={food?.value}
                          className="item"
                          onClick={() => handleFoodRemove(food)}
                        >
                          <span>
                            <IconContext.Provider
                              value={{
                                className: "close-icon",
                                style: { color: "#ffffff" },
                              }}
                            >
                              <AiOutlineCloseCircle />
                            </IconContext.Provider>
                          </span>
                          <span>{food?.label}</span>
                        </div>
                      );
                    })}
                </div>
              </>
            )}
          </div>
          <div
            className={mobile ? "select-reveal" : "select-reveal desktop"}
            style={{
              height: allergiesSelectOpen ? "auto" : "60px",

              minHeight: mobile ? "auto" : "280px",
            }}
          >
            <div
              className="top"
              style={{
                justifyContent: mobile ? "space-between" : "center",
                flexDirection:
                  languageDirection === "rtl" ? "row-reverse" : "row",
              }}
              onClick={
                mobile
                  ? () => setAllergiesSelectOpen(!allergiesSelectOpen)
                  : null
              }
            >
              <span>{t("allergies")} </span>

              {mobile && (
                <span>
                  <IconContext.Provider value={{ className: "chevron-icon" }}>
                    {allergiesSelectOpen ? (
                      <HiOutlineChevronDown />
                    ) : (
                      <HiOutlineChevronUp />
                    )}
                  </IconContext.Provider>
                </span>
              )}
            </div>

            {allergiesSelectOpen && (
              <>
                <div
                  className={
                    mobile ? "select-wrapper" : "select-wrapper desktop"
                  }
                  style={{ direction: languageDirection }}
                >
                  <Select
                    options={translatedData?.allergies}
                    styles={customStyles}
                    onChange={handleAllergiesSelection}
                    placeholder={t("select")}
                  />
                </div>
                <div
                  className="pre-selected"
                  style={{ width: mobile ? "50vw" : "13vw" }}
                >
                  {allergies?.length > 0 &&
                    allergies.map((allergy) => {
                      return (
                        <div
                          key={allergy?.value}
                          className="item"
                          onClick={() => handleRemoveAllergies(allergy)}
                        >
                          <span>
                            <IconContext.Provider
                              value={{
                                className: "close-icon",
                                style: { color: "#ffffff" },
                              }}
                            >
                              <AiOutlineCloseCircle />
                            </IconContext.Provider>
                          </span>
                          <span>{allergy.label}</span>
                        </div>
                      );
                    })}
                </div>
              </>
            )}
          </div>

          <div
            className={mobile ? "select-reveal" : "select-reveal desktop"}
            style={{
              height: hubbiesSelectOpen ? "auto" : "60px",
              minHeight: mobile ? "auto" : "280px",
            }}
          >
            <div
              className="top"
              style={{
                justifyContent: mobile ? "space-between" : "center",
                flexDirection:
                  languageDirection === "rtl" ? "row-reverse" : "row",
              }}
              onClick={
                mobile ? () => setHubbiesSelectOpen(!hubbiesSelectOpen) : null
              }
            >
              <span>{t("hubbies")}</span>
              {mobile && (
                <span>
                  <IconContext.Provider value={{ className: "chevron-icon" }}>
                    {hubbiesSelectOpen ? (
                      <HiOutlineChevronDown />
                    ) : (
                      <HiOutlineChevronUp />
                    )}
                  </IconContext.Provider>
                </span>
              )}
            </div>

            {hubbiesSelectOpen && (
              <>
                <div
                  className={
                    mobile ? "select-wrapper" : "select-wrapper desktop"
                  }
                  style={{ direction: languageDirection }}
                >
                  <Select
                    options={translatedData?.hubbies}
                    styles={customStyles}
                    onChange={handleHubbiesSelection}
                    placeholder={t("select")}
                  />
                </div>
                <div
                  className="pre-selected"
                  style={{ width: mobile ? "50vw" : "13vw" }}
                >
                  {hubbies.length > 0 &&
                    hubbies.map((hubby) => {
                      return (
                        <div
                          key={hubby?.value}
                          className="item"
                          onClick={() => handleRemoveHubby(hubby)}
                        >
                          <span>
                            <IconContext.Provider
                              value={{
                                className: "close-icon",
                                style: { color: "#ffffff" },
                              }}
                            >
                              <AiOutlineCloseCircle />
                            </IconContext.Provider>
                          </span>
                          <span>{hubby?.label}</span>
                        </div>
                      );
                    })}
                </div>
              </>
            )}
          </div>

          <div
            className={
              mobile ? "select-reveal last" : "select-reveal last desktop"
            }
            style={{
              height: environmentAccordionHeight,
            }}
          >
            <div
              className="top"
              style={{
                justifyContent: mobile ? "space-between" : "center",
                flexDirection:
                  languageDirection === "rtl" ? "row-reverse" : "row",
              }}
              onClick={
                mobile
                  ? () => setEnvironmentSelectOpen(!environmentSelectOpen)
                  : null
              }
            >
              <span>{t("goes_to_school")} </span>
              {mobile && (
                <span>
                  <IconContext.Provider value={{ className: "chevron-icon" }}>
                    {environmentSelectOpen ? (
                      <HiOutlineChevronDown />
                    ) : (
                      <HiOutlineChevronUp />
                    )}
                  </IconContext.Provider>
                </span>
              )}
            </div>

            {environmentSelectOpen && (
              <>
                <div
                  className={
                    mobile ? "select-wrapper" : "select-wrapper desktop"
                  }
                  style={{ direction: languageDirection }}
                >
                  <Select
                    options={translatedData?.environment}
                    onMenuOpen={() => setMealsSelectOptionsIsOpen(true)}
                    styles={customStyles}
                    value={selectedEnvironment}
                    onChange={handleEnvironmentSelection}
                  />
                </div>

                {mealsSelectOpen && (
                  <div
                    className={
                      mobile ? "select-wrapper" : "select-wrapper desktop"
                    }
                    ref={mealsSelectRef}
                  >
                    <Select
                      onMenuOpen={() => setMealsSelectOptionsIsOpen(true)}
                      options={mealsSelection}
                      value={mealsIncluded}
                      styles={customStyles}
                      onChange={setMealsIncluded}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <div className={mobile ? "button-wrapper" : "button-wrapper desktop"}>
          <div
            className="btn submit-profile-changes"
            onClick={handleProfileChanges}
          >
            {t("save")}
          </div>
          <div className="btn back" onClick={() => navigate("/")}>
            {t("back")}
          </div>
          <div className="btn delete-btn" onClick={handleDeleteAccount}>
            {t("delete")}
          </div>
        </div>
      </div>
      {!mobile && <FooterDesktop />}
    </>
  );
};

export default EditProfile;
