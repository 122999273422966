import { FaSearch } from "react-icons/fa";
import { useEffect, useState, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import HeaderDesktop from "./header-desktop";
import HeaderMobile from "./header-mobile";
import YellowSeparator from "./yellow-separator";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import profileService from "../services/profileService";
import "./styles/users-list.css";
import { MdDeleteForever } from "react-icons/md";
import {
  setDeletionPopupOpenRedux,
  setItemForDeletion,
  setDeletionPopupContext,
} from "../redux/reducer";
import { Circles } from "react-loader-spinner";

const UsersList = () => {
  const mobile = useMediaQuery({ maxWidth: 800 });
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const languageDirection = i18n.language === "he" ? "rtl" : "ltr";
  const navigate = useNavigate();
  const role = useSelector((state) => state.mainSlice.role);
  const [usersList, setUsersList] = useState([]);
  const [error, setError] = useState("");
  const [offset, setOffset] = useState(0);
  const [hasMoreUsers, setHasMoreUsers] = useState(true);
  const [fetchingUsers, setFetchingUsers] = useState(false);
  const limit = 50;
  const [totalRecords, setTotalRecords] = useState(0);
  const [email, setEmail] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [displayingSearchResults, setDisplayingSearchResults] = useState(false);
  const fetchUsersList = useCallback(
    async (newOffset) => {
      if (fetchingUsers) return;
      setFetchingUsers(true);
      setIsSearching(true);

      try {
        const list = await profileService.getUserList({
          limit,
          offset: newOffset,
        });

        if (list.message === "success") {
          const newUsers = list.users;
          setUsersList(newUsers);
          setHasMoreUsers(newUsers.length === limit);
          setOffset(newOffset);

          // Update total records if it's first page
          if (newOffset === 0) {
            setTotalRecords(newOffset + newUsers.length);
          } else {
            setTotalRecords(
              Math.max(totalRecords, newOffset + newUsers.length)
            );
          }
        } else {
          console.error("Error fetching users:", list.message);
          setUsersList([]);
          setError(list.message);
        }
      } catch (error) {
        console.error("Error fetching users:", error);
        setError("Failed to fetch users");
      } finally {
        setFetchingUsers(false);
        setIsSearching(false);
      }
    },
    [fetchingUsers]
  );

  useEffect(() => {
    if (role !== 2) {
      navigate("/");
    } else {
      fetchUsersList(0);
    }
  }, []);

  useEffect(() => {
    if (mobile) {
      navigate("/");
    }
  }, [mobile]);

  const formatDate = useCallback((isoDateString) => {
    const date = new Date(isoDateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }, []);

  const handleRemoveUser = async (user) => {
    dispatch(setItemForDeletion(user));
    dispatch(setDeletionPopupContext("household"));
    dispatch(setDeletionPopupOpenRedux(true));
  };

  const handlePreviousPage = () => {
    const newOffset = Math.max(offset - limit, 0);
    fetchUsersList(newOffset);
  };

  const handleNextPage = () => {
    const newOffset = offset + limit;
    fetchUsersList(newOffset);
  };

  const renderedUsers = useMemo(
    () =>
      usersList.map((user) => (
        <tr key={user.box_identifier}>
          <td>{user.box_identifier}</td>
          <td style={{ minWidth: "120px" }}>{formatDate(user.created_at)}</td>
          <td>{user.parent_name}</td>
          <td>{user.parent_last_name}</td>
          <td>{user.email}</td>
          <td>{user.child_name}</td>
          <td>{user.year_of_birth}</td>
          <td>{user.month_of_birth}</td>
          <td>
            {!user.diet_names ||
            user.diet_names === "Null" ||
            user.diet_names === null ||
            user.diet_names === "None"
              ? `${t("Eats Everything")}`
              : user.diet_names}
          </td>
          <td>
            {!user.allergy_names ||
            user.allergy_names === "None" ||
            user.allergy_names === null ||
            user.allergy_names === "NULL"
              ? `${t("None")}`
              : user.allergy_names}
          </td>
          <td>{user.hubbie_names}</td>
          <td>{user.learning_environment}</td>
          <td
            style={{ textAlign: "center", cursor: "pointer" }}
            onClick={() => handleRemoveUser(user)}
          >
            <MdDeleteForever />
          </td>
        </tr>
      )),
    [usersList, formatDate, t]
  );
  const handleSearch = async () => {
    setError("");
    if (email === "" || email.length < 3) {
      setIsSearching(false);
      setError("please enter at least 3 characters");
      return;
    }
    try {
      setIsSearching(true);

      const results = await profileService.searchProfile(email);
      console.log("results", results);

      if (results.message === "success") {
        setUsersList(results.searchResults);
        setTotalRecords(results.searchResults.length);
        setDisplayingSearchResults(true);
        return;
      } else {
        setError(results.message);
        return;
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsSearching(false);
    }
  };

  return (
    <>
      <div className="user-list-container">
        <HeaderDesktop />
        <div className="user-list-main-content" dir={languageDirection}>
          <div className="search-wrapper">
            <h1>{t("Registered Users")}</h1>
            <div className="search">
              {displayingSearchResults ? (
                <>
                  <div className="backToMainList">
                    <button
                      onClick={() => {
                        setDisplayingSearchResults(false);
                        fetchUsersList(0);
                        setEmail("");
                      }}
                    >
                      Back to full list
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <FaSearch size={"20px"} />
                  <input
                    type="text"
                    placeholder="By Email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                  <button
                    onClick={handleSearch}
                    disabled={isSearching}
                    className={isSearching ? "disabled" : ""}
                  >
                    Search
                  </button>
                </>
              )}
            </div>
          </div>

          {!isSearching && (
            <div className="pagination-info">
              {"Showing"} {offset + 1} - {offset + usersList.length} {"of"}{" "}
              {totalRecords} {"records"}
            </div>
          )}

          {error && (
            <p className="error-message" style={{ color: "red" }}>
              {error}
            </p>
          )}

          {isSearching ? (
            <div className="searching-loader">
              <Circles
                height="150"
                width="150"
                color="#4fa94d"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          ) : (
            <>
              <div className="table-container">
                <table
                  className="users-table"
                  style={{ direction: languageDirection }}
                >
                  <thead>
                    <tr>
                      <th>{t("box number")}</th>
                      <th>{t("creation date")}</th>
                      <th>{t("parent name")}</th>
                      <th>{t("parent last name")}</th>
                      <th>{t("email")}</th>
                      <th>{t("child name")}</th>
                      <th>{t("year of birth")}</th>
                      <th>{t("month of birth")}</th>
                      <th>{t("life style")}</th>
                      <th>{t("allergies")}</th>
                      <th>{t("hubbies")}</th>
                      <th>{t("goes to")}</th>
                      <th>{t("Delete")}</th>
                    </tr>
                  </thead>
                  <tbody>{renderedUsers}</tbody>
                </table>
              </div>

              <div className="pagination-info">
                {"Showing"} {offset + 1} - {offset + usersList.length} {"of"}{" "}
                {totalRecords} {"records"}
              </div>
            </>
          )}
        </div>

        {!isSearching && !displayingSearchResults && (
          <div className="pagination-buttons">
            <button
              className="previous-button"
              onClick={handlePreviousPage}
              disabled={offset === 0 || fetchingUsers}
            >
              {t("Previous")}
            </button>
            <button
              onClick={handleNextPage}
              disabled={!hasMoreUsers || fetchingUsers}
            >
              {t("Next")}
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default UsersList;
